@import "../global/_mixins";

.banner {
    background-color: var(--light-grey);
    padding-top: getRem(48);
    padding-bottom: getRem(64);

    .row {
        display: flex;

        @include breakpoint(mobile-tablet) {
            display: block;
        }
    }

    .breadcrumbs {
        list-style: none;
        margin: 0;
        padding: 0;

        li,
        a {
            color: #6F6F6F;
            @include font(14, 21);
            letter-spacing: getRem(2);
            text-transform: uppercase;
        }

        li {
            display: inline-block;
            vertical-align: middle;
            position: relative;

            &:not(:last-child) {
                &:after {
                    content: '/';
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: getRem(6);
                    margin-right: getRem(8);
                }
            }
        }

        a {
            font-weight: 700;
        }
    }

    .textContainer {

        .subTitle {
            color: var(--red);
            @include font(24, 36);
            font-weight: 700;
            margin: 0;

            @include breakpoint(mobile) {
                @include font(18, 36);
            }
        }

        h1 {
            @include font(70, 81);
            margin: getRem(12) 0 0;

            @include breakpoint(mobile) {
                font-size: getRem(40);
                @include lineHeightRatio(40, 52);
            }
        }

        .metaContainer {
            // display: grid;
            // grid-template-columns: auto auto auto auto;
            display: flex;
            grid-gap: getRem(16);
            margin-top: getRem(24);

            @include breakpoint(mobile-tablet) {
                flex-wrap: wrap;
                grid-template-columns: auto auto;
            }

            p {
                @include font(14, 18);
                display: flex;
                margin: auto 0;

                svg {
                    margin-right: getRem(6);
                    flex-shrink: 0;

                    path {
                        fill: #292929;
                    }
                }
            }
        }

        .actionsContainer {
            margin-top: getRem(24);
            margin-bottom: getRem(32);
            display: flex;
            grid-gap: getRem(8);

            @include breakpoint(mobile) {
                flex-wrap: wrap;
                margin-bottom: 0;
            }

            .viewCount {
                min-width: getRem(70);
                display: flex;
                align-items: center;
                @include font(16, 26);
                font-weight: 700;
                margin: 0;

                @include breakpoint(mobile) {
                    width: 100%;
                }

                svg {
                    margin-right: getRem(8);
                }
            }

            .actionButtonsContainer {
                display: flex;
                grid-gap: getRem(8);
            }

            .actionButton {
                height: 100%;
                max-height: getRem(45);
                background: rgba(0, 0, 0, 0.5);
                border: 1px solid rgba(255, 255, 255, 0.2);
                border-radius: getRem(2);
                cursor: pointer;
                display: flex;
                align-items: center;
                margin-top: 0;
                padding: getRem(8);
                transition: all .2s ease-in-out;

                &:hover {
                    background: var(--yellow);

                    span {
                        color: var(--black);
                    }

                    svg {
                        path {
                            stroke: var(--black);
                        }
                    }
                }

                &.isBookmarked {
                    svg {
                        path {
                            fill: var(--white);
                        }
                    }

                    &:hover {
                        svg {
                            path {
                                fill: var(--black);
                            }
                        }
                    }
                }

                span {
                    color: var(--white);
                    @include font(18, 18);
                    font-weight: 700;
                    margin-left: getRem(8);
                    position: static;
                    transform: none;
                    transition: all .2s ease-in-out;
                }

                svg {
                    width: getRem(20);
                    overflow: visible;
                    z-index: 0;

                    path {
                        fill: transparent;
                        stroke: var(--white);
                        transition: all .2s ease-in-out;
                    }
                }
            }

            .followButton {
                &:hover {
                    svg {
                        path {
                            fill: var(--black);
                        }
                    }
                }

                svg {
                    path {
                        fill: var(--white);
                        stroke: transparent;
                    }
                }
            }

            .shareButton {
                &:hover {
                    svg {
                        path {
                            fill: var(--black);
                        }
                    }
                }

                svg {
                    path {
                        fill: var(--white);
                        stroke: transparent;
                    }
                }
            }
        }

        .followContainer {
            width: auto;
            position: relative;

            .followOptionsContainer {
                width: getRem(340);
                background: var(--white);
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                display: none;
                position: absolute;
                top: 100%;
                left: 0;
                z-index: 1000;

                @include breakpoint(tablet) {
                    left: auto;
                    right: 0;
                }

                @include breakpoint(mobile) {
                    // width: 100%;
                    left: 0;
                    right: auto;
                }

                .maxCountContainer {
                    .top {
                        background: var(--red);
                        box-shadow: 0 getRem(2) getRem(4) rgba(0, 0, 0, 0.3);
                        padding: getRem(12) getRem(16);

                        p {
                            color: #FFFFFF;
                            font-weight: 700;
                            font-size: getRem(18);
                            line-height: getRem(18);
                            margin: 0;
                            text-align: center;
                            text-transform: none;
                        }
                    }

                    .bottom {
                        padding: getRem(10) getRem(16);

                        p {
                            font-size: getRem(16);
                            line-height: getRem(26);
                            color: #292929;
                            margin: 0;
                            text-transform: none;
                        }

                        a {
                            font-weight: 700;
                            font-size: getRem(16);
                            line-height: getRem(26);
                            color: var(--red);
                            text-transform: none;
                        }
                    }
                }

                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;

                    li {
                        border-bottom: 1px solid rgba(45, 45, 45, 0.2);
                        margin: 0;
                        padding: getRem(16) getRem(24);
                        position: relative;

                        &:last-child {
                            border-bottom: none;
                        }

                        &.disabled {
                            opacity: 0.3;

                            * {
                                pointer-events: none;
                            }
                        }

                        label {
                            width: 100%;
                            height: 100%;
                            opacity: 0;
                            position: absolute;
                            top: 0;
                            left: 0;
                        }

                        .contentContainer {
                            display: flex;
                            align-items: center;
                            display: grid;
                            grid-template-columns: 5fr 3fr 1fr;
                        }

                        p {
                            @include font(21, 26);
                            margin: 0;
                            text-transform: none;

                            &.type {
                                @include font(14, 24);
                                color: #777676;
                                // margin: 0 getRem(60) 0 getRem(16);
                            }
                        }

                        input {
                            margin-left: auto;
                            margin-right: 0;
                            flex-shrink: 0;
                        }
                    }
                }
            }
        }

        .followContainerOpen {
            .followButton {
                background: var(--red);
            }

            .followOptionsContainer {
                display: inline-block;
            }
        }
    }
}

.campaignBanner {
    .textContainer {
        padding-right: getRem(60);

        @include breakpoint(mobile-tablet) {
            padding-right: 0;
        }
    }

    .imageContainer {
        // width: getRem(630);
        // height: getRem(354);
        width: 43.75vw;
        max-width: getRem(630);
        height: 24.6vw;
        max-height: getRem(354);
        flex-shrink: 0;
        margin-top: getRem(36);
        position: relative;

        &.responsive {
            display: none;
        }

        @include breakpoint(mobile-tablet) {
            width: 100%;
            max-width: none;
            height: getRem(200);
            margin: getRem(24) 0;
            display: none;

            &.responsive {
                display: block;
            }
        }
    }
}

.briefsBanner {
    background: none;
    // overflow: hidden;
    padding: 0;
    position: relative;

    &:before {
        content: '';
        width: 100%;
        height: calc(100% + 48px);
        background-color: var(--light-grey);
        display: inline-block;
        position: absolute;
        top: getRem(-48);
        left: 50%;
        transform: translateX(-50%);
        z-index: 0;
    }

    .wrapper {
        padding-left: 0;
        position: relative;
        z-index: 1;

        @include breakpoint(mobile) {
            padding-right: 0;
        }

        &>div {
            &:first-child {
                padding-top: getRem(48);
                padding-left: getRem(100);

                @include breakpoint(mobile) {
                    padding-top: getRem(32);
                }
            }

            &:last-child {
                margin-left: 0;
            }
        }
    }

    .row {
        max-width: 40.375rem;
        position: relative;
        z-index: 2;

        @include breakpoint(tablet) {
            max-width: 50%;
            padding-left: getRem(40) !important;
        }

        @include breakpoint(mobile) {
            padding-left: 0 !important;
            max-width: none;
        }
    }

    .textContainer {
        padding: getRem(48) getRem(36) getRem(32) 0;
        position: relative;
        z-index: 1;

        @include breakpoint(mobile-tablet) {
            padding-top: 0;
            padding-right: 0;
        }
    }

    .imageContainer {
        height: 100%;
        flex-shrink: 0;
        z-index: 1;
        position: absolute;
        -webkit-clip-path: unset;
        clip-path: unset;
        width: 50%;
        top: 0;
        right: 0;
        z-index: 0;

        &.responsiveImage {
            width: 100%;
            height: auto;
            position: static;
            top: 0;
            left: 0;

            span,
            img {
                width: 100% !important;
                max-width: none !important;
                height: auto !important;
                max-height: none !important;
                position: static !important;
            }

            img {
                object-fit: initial !important;
            }
        }
    }

    .yellowBox {
        width: 54vw;
        max-width: getRem(778);
        background: var(--yellow);
        border-radius: getRem(8) getRem(8) 0 0;
        // margin-top: getRem(-72);
        // margin-left: getRem(76);
        // margin-top: getRem(34);
        padding: getRem(40) getRem(44);
        position: relative;
        z-index: 1;

        @include breakpoint(tablet) {
            margin-left: getRem(40) !important;
        }

        @include breakpoint(mobile) {
            width: 100%;
            margin-left: 0 !important;
            padding: getRem(24);
        }

        &:before {
            content: '';
            width: 100vw;
            height: calc(100% - 72px);
            background: var(--white);
            display: inline-block;
            position: absolute;
            bottom: 0;
            // left: getRem(-100);
            z-index: -1;

            @media (min-width: 1441px) {
                left: calc(((100vw - 1440px) / 2) * -1);
            }

            @include breakpoint(tablet) {
                left: calc((((100vw - var(--max-width)) / 2) * -1) - 40px);
            }

            @include breakpoint(mobile) {
                left: 50%;
                transform: translateX(-50%);
            }

        }

        &:after {
            content: '';
            width: 100%;
            height: 100%;
            background: var(--yellow);
            border-radius: getRem(8) getRem(8) 0 0;
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
        }

        @include breakpoint(mobile-tablet) {
            margin-top: getRem(16);
        }

        .boxInner {
            position: relative;
            z-index: 1;
        }

        h2 {
            @include font(14, 18);
            font-weight: 400;
            margin: 0;
        }

        p {
            font-size: getRem(18);
            @include lineHeightRatio(18, 28);
            margin: getRem(16) 0 0;

            @include breakpoint(mobile) {
                font-size: getRem(16);
                @include lineHeightRatio(16, 22);
            }
        }

        iframe {
            width: 100%;
            aspect-ratio: 1.78;
            margin-top: getRem(16);
        }
    }
}

.masterClassBanner {
    background: none;
    padding-top: getRem(48);

    h1 {
        color: var(--red);
        @include font(24, 36);
        font-weight: 700;
        margin: 0;

        @include breakpoint(mobile) {
            @include font(18, 24);
            @include lineHeightRatio(18, 24);
        }
    }

    .row {
        margin-top: getRem(36);
        position: relative;
    }

    .videoContainer {
        // width: 71.5%;
        width: calc(100% - 354px);
        // display: flex;
        position: relative;

        @include breakpoint(mobile-tablet) {
            width: 100%;
        }

        .videoInner {
            padding-bottom: 56.25%;
            position: relative;
        }

        iframe {
            width: 100%;
            max-width: getRem(887);
            // height: auto;
            height: 100%;
            max-height: getRem(497);
            // aspect-ratio: 1.78;
            margin-bottom: auto;
            position: absolute;
            top: 0;
            left: 0;

            @include breakpoint(mobile-tablet) {
                max-width: none;
                max-height: none;
            }
        }
    }

    .textContainer {
        // width: 28.5%;
        width: getRem(354);
        background: var(--yellow);
        padding: getRem(20);
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;

        @include breakpoint(mobile-tablet) {
            width: 100%;
            height: auto;
            margin-top: getRem(16);
            position: relative;
            left: 0;
            right: auto;
        }

        &::after {
            content: "";
            position: absolute;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjY2IiBoZWlnaHQ9IjU4IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0tOS4xNDcgMEguNTM5QzMwLjMxIDEuMjEzIDQ1LjM5NyAzMi4xNjIgNzYuODggMjcuM2MxMS4zNi0xLjc1MiAyMC44MTUtOC4zOTYgMzIuNjgtOC40OTQgMjguMzk2LS4yMyA0Mi4zMzYgMzIuNTAyIDY4LjgyNyAzOC4xNzUgNy45NDQgMS43MDIgMTYuMjg0IDEuNDc4IDIzLjYzNC0yLjI1NyA2LjQ0Ny0zLjI3NCAxMS40NTUtOC42OTcgMTUuNzk1LTE0LjM2NyA3LjUtOS44MDQgMTQuMTgtMjAuNDMgMjMuMTQ2LTI5LjAxMyA0Ljg3OC00LjY2NSAxMC43NjQtOC45OTcgMTcuNDQ4LTEwLjQyIDIuNTk2LS41NTMgNS4yMTktLjc4OCA3Ljg0Ny0uOTI0aDEwLjkxIiBmaWxsPSIjRkZCQzBEIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=");
            height: getRem(58);
            width: 100%;
            background-position: top right;
            background-repeat: no-repeat;
            top: 100%;
            left: 0;
            z-index: 0;
        }

        .textInner {
            height: 100%;
            background: var(--white);
        }

        .imageContainer {
            width: 100%;
            height: getRem(194);
            max-height: 50%;
            position: relative;

            @include breakpoint(mobile-tablet) {
                height: 42vw;
                max-height: none;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .textContent {
            height: calc(100% - 194px);
            min-height: 50%;
            overflow-y: scroll;
            overflow-x: hidden;
            padding: getRem(16);

            @include breakpoint(mobile-tablet) {
                height: auto;
                min-height: 0;
            }
        }

        p.h1 {
            @include font(20, 24);
            font-weight: bold;
        }

        .hostItem {
            margin-bottom: getRem(12);

            &:last-child {
                margin-bottom: 0;
            }
        }

        p {
            @include font(16, 26);
            margin: getRem(12) 0 0;

            &.time {
                color: var(--red);
                font-weight: bold;
            }

            &.hostLabel,
            &.name,
            &.title,
            &.redLabel {
                @include font(16, 26);
                margin: 0;
            }

            &.hostLabel {
                font-weight: 600;
                margin-top: getRem(12);
            }

            &.title {
                font-style: italic;
                margin-top: 0;
            }

            &.redLabel {
                font-weight: bold;
                margin-top: getRem(8);
            }
        }

        .filesList {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                padding: 0 0 getRem(16);

                &:not(:last-child) {
                    border-bottom: 1px solid rgba(45, 45, 45, 0.2);
                }
            }

            .row {
                margin-top: 0;
            }

            svg {
                margin-top: getRem(4);
                margin-right: getRem(10);
                flex-shrink: 0;
            }

            .textContent {
                width: 100%;
            }

            p {
                margin: 0;
            }

            .openLabel {
                color: var(--text-light2);
                display: flex;
                align-items: center;
                font-weight: 700;
                margin-right: getRem(8);
            }

            .contentType {
                color: var(--text-light2);
                display: flex;
                justify-content: space-between;
                margin-top: getRem(10);
            }
        }

        .actionsContainer {
            display: flex;
            align-items: center;
            margin-top: getRem(8);
            padding-bottom: getRem(10);

            .viewCount {
                display: flex;
                align-items: center;
                margin: 0;

                svg {
                    flex-shrink: 0;
                }

                span {
                    color: var(--grey);
                    @include font(14, 29);
                    font-weight: 400;
                    margin-left: getRem(4);
                }
            }

            .bookmarkButtonContainer {
                button {
                    background: transparent !important;
                    border: none;
                    cursor: pointer;
                    margin-right: getRem(16);
                    padding: 0;
                    transition: filter 0.33s cubic-bezier(0.4, 0, 0.2, 1);

                    &:hover {
                        filter: drop-shadow(0px 1px 6px #ffbc0d);

                        svg {
                            path {
                                fill: transparent;
                                stroke: var(--yellow);
                            }
                        }
                    }

                    svg {
                        path {
                            fill: transparent;
                            stroke: var(--yellow);
                        }
                    }

                    span {
                        display: none;
                        ;
                    }
                }
            }
        }
    }
}

.shareButton {
    position: relative;
    z-index: 10;

    @include breakpoint(mobile) {
        // width: 100%;
    }

    button {
        height: 100%;
        max-height: getRem(45);
        background: rgba(0, 0, 0, 0.5);
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: getRem(2);
        color: #FFFFFF;
        display: flex;
        align-items: center;
        margin: 0;
        padding: getRem(8);
        font-weight: 700;
        font-size: 18px;
        line-height: 18px;
        transition: all 0.2s ease-in-out;

        &:before,
        &:after {
            display: none;
        }

        &:hover {
            background: var(--yellow);

            span {
                color: var(--black);
            }
        }

        span {
            position: static;
            transform: none;
            margin: 0 getRem(8);
            transition: all 0.2s ease-in-out;
        }

        svg {
            width: getRem(20);
            overflow: visible;
            z-index: 0;

            g {
                fill: var(--white);
            }

            path {
                transition: all 0.2s ease-in-out;
            }
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            border-bottom: 1px solid rgba(45, 45, 45, 0.2);
            margin: 0;
            padding: getRem(16) getRem(24);
            position: relative;

            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                padding-bottom: 0;
            }

            &:last-child {
                border-bottom: none;
            }

            &.disabled {
                opacity: 0.3;

                * {
                    pointer-events: none;
                }
            }

            label {
                width: 100%;
                height: 100%;
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
            }

            .content-container {
                display: flex;
                align-items: center;
                display: grid;
                grid-template-columns: 5fr 3fr 1fr;
            }

            p {
                color: #292929;
                font-size: 21px;
                line-height: 26px;
                margin: 0;
                text-transform: none;

                &.type {
                    font-size: 14px;
                    line-height: 24px;
                    color: #777676;
                    // margin: 0 getRem(60) 0 getRem(16);
                }
            }

            input {
                margin-left: auto;
                margin-right: 0;
                flex-shrink: 0;
            }

            svg {
                path {
                    stroke: var(--black) !important;
                    fill: var(--black) !important;
                }
            }
        }
    }

    &.show {
        ul {
            display: block;
        }
    }
}